import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setClaim,
  updateClaimItem,
} from "../../../store/slices/Claims/ClaimSlice";
import { arrayOf } from "prop-types";
import {
  ClaimeportedIssue,
  CREDIT_REASON,
  FREIGHT_CLAIM,
  FREIGHT_CLAIM_REASON,
  FreightClaimReasons,
} from "../../../helpers/const";

const ClaimInfoModule = ({ show, disabled }) => {
  const { initial_order, claim_type, claim } = useSelector(
    (state) => state.claims
  );

  const dispatch = useDispatch();

  const contactUpdate = (value, use_case) => {
    let temp_claim = { ...claim };
    switch (use_case) {
      case "name":
        temp_claim = {
          ...claim,
          customer: {
            ...claim.customer,
            name: value,
            reported_by: value,
          },
        };
        break;
      case "phone":
        const cleaned = value.replace(/\D/g, "");
        const area_code = cleaned.slice(0, 3);
        const prefix = cleaned.slice(3, 6);
        const suffix = cleaned.slice(6, 10);

        let formatted_phone = "";

        if (cleaned.length > 6)
          formatted_phone = `(${area_code}) ${prefix}-${suffix}`;
        else if (cleaned.length > 3)
          formatted_phone = `(${area_code}) ${prefix}`;
        else if (cleaned.length > 0) formatted_phone = `(${area_code}`;

        temp_claim = {
          ...claim,
          customer: {
            ...claim.customer,
            contact: {
              ...claim.customer.contact,
              phone: formatted_phone,
              reported_by_phone: formatted_phone,
            },
          },
        };
        break;
      case "email":
        temp_claim = {
          ...claim,
          customer: {
            ...claim.customer,
            contact: {
              ...claim.customer.contact,
              emails: [value],
              reported_by_email: [value],
            },
          },
        };
        break;
      case "reason":
        temp_claim = {
          ...claim,
          claim_reason: CREDIT_REASON.filter(
            (reason) => reason.label === value
          )[0].id,
        };
        break;
      case "bol":
        temp_claim = {
          ...claim,
          replacement_order_num: value,
        };
        break;
      case "freight_reason":
        temp_claim = {
          ...claim,
          freight_claim_reason: FreightClaimReasons.filter(
            (reason) => reason.label === value
          )[0].id,
        };
        break;
      case "reported":
        temp_claim = {
          ...claim,
          reported_issue: ClaimeportedIssue.filter(
            (reason) => reason.label === value
          )[0].id,
        };
        break;
      case "driver":
        temp_claim = {
          ...claim,
          driver_aware: value ? "Y" : "N",
        };
        break;
    }

    dispatch(setClaim(temp_claim));
  };

  const useProps = {
    options: CREDIT_REASON,
    getOptionLabel: (option) => option.label,
  };

  const freightProps = {
    options: FreightClaimReasons,
    getOptionLabel: (option) => option.label,
  };

  const reportedProps = {
    options: ClaimeportedIssue,
    getOptionLabel: (option) => option.label,
  };

  return (
    <>
      <Card hidden={!show}>
        <Card.Title className="bottom-border-seperator">
          {initial_order.customer.name} ({initial_order.customer.customer_num})
        </Card.Title>
        <Row>
          <Col>
            <Card.Title className="mb-0">Contact Information</Card.Title>
            <Card.Body className="mt-0">
              <b>{initial_order.customer.contact.name}</b>
              <br />
              <b>Phone:</b> {initial_order.customer.contact.phone}
              <br />
              <b>Email:</b> {initial_order.customer.contact.emails[0]}
            </Card.Body>
          </Col>

          <Col>
            <Card.Title className="mb-0">Original Order Information</Card.Title>
            <Card.Body className="mt-0">
              <b>Invoice Number: </b> {initial_order.customer_invoice_num}
              <br />
              <b>Order Number: </b>
              {initial_order.customer_order_num}
              <br />
              <b>Customer PO: </b>
              {initial_order.customer_po}
            </Card.Body>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <Card.Title className="mb-0">Billing Address</Card.Title>
            <Card.Body className="mt-0">
              {initial_order.customer.billing_address.street}
              <br />
              {initial_order.customer.billing_address.route}
              <br />
              {initial_order.customer.billing_address.city +
                " " +
                initial_order.customer.billing_address.state +
                " " +
                initial_order.customer.billing_address.zip_code}
            </Card.Body>
          </Col>
          <Col>
            <Card.Title className="mb-0">Shipping Address</Card.Title>
            <Card.Body className="mt-0">
              {initial_order.customer.shipping_address.street.trim().length > 1
                ? initial_order.customer.shipping_address.street.trim() + "\n"
                : ""}
              {initial_order.customer.shipping_address.route}
              <br />
              {initial_order.customer.shipping_address.city +
                " " +
                initial_order.customer.shipping_address.state +
                " " +
                initial_order.customer.shipping_address.zip_code}
            </Card.Body>
          </Col>
          {initial_order.freight.is_drop_ship && (
            <Col>
              <Card.Title className="mb-0">Drop Ship Address</Card.Title>
              <Card.Body className="mt-0">
                {initial_order.freight.drop_ship_address.address.street.trim()
                  .length > 1
                  ? initial_order.freight.drop_ship_address.address.street.trim() +
                    "\n"
                  : ""}
                {initial_order.freight.drop_ship_address.address.route}
                <br />
                {initial_order.freight.drop_ship_address.address.city +
                  " " +
                  initial_order.freight.drop_ship_address.address.state +
                  " " +
                  initial_order.freight.drop_ship_address.address.zip_code}
              </Card.Body>
            </Col>
          )}
        </Row>
        <div className="cust-return">
          <Card.Title className="mt-5 bottom-border-seperator">
            Claim Fields
          </Card.Title>
          <Row>
            <Col className="cust-return-name">
              <div className="info-header">Contact Name</div>
              <TextField
                className="w-100"
                disabled={disabled}
                value={claim.customer.name}
                onChange={(e) => contactUpdate(e.target.value, "name")}
              ></TextField>
            </Col>
            <Col>
              <div className="info-header">Contact Phone</div>
              <TextField
                className="w-100"
                disabled={disabled}
                value={claim.customer.contact.phone}
                onChange={(e) => contactUpdate(e.target.value, "phone")}
              ></TextField>
            </Col>
            <Col>
              <div className="info-header">Contact Email</div>
              <TextField
                className="w-100"
                disabled={disabled}
                value={claim.customer.contact.emails[0]}
                onChange={(e) => contactUpdate(e.target.value, "email")}
                inputProps={{ maxLength: 30 }}
              ></TextField>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="info-header">Claim Reason</div>
              <Autocomplete
                disabled={disabled}
                disableClearable
                {...useProps}
                className="autocomplete-input-credit"
                onChange={(e) => contactUpdate(e.target.textContent, "reason")}
                value={
                  CREDIT_REASON.filter(
                    (prob) => prob.id === claim.claim_reason
                  )[0]
                }
                isOptionEqualToValue={(option, value) =>
                  option.id === value ? value.id : value
                }
                renderInput={(params) => (
                  <TextField
                    className="autocomplete-problems"
                    {...params}
                    variant="standard"
                  />
                )}
              />
            </Col>
            <Col>
              <div className="info-header">Replacement BOL</div>
              <TextField
                className="w-100"
                disabled={disabled}
                value={claim.replacement_order_num}
                onChange={(e) => contactUpdate(e.target.value, "bol")}
              ></TextField>
            </Col>
          </Row>
        </div>
        <div className="cust-return" hidden={claim_type !== FREIGHT_CLAIM.id}>
          <Card.Title className="mt-5 bottom-border-seperator">
            Freight Claim Info
          </Card.Title>
          <Row>
            <Col className="cust-return-name">
              <div className="info-header">Freight Claim Reason</div>
              <Autocomplete
                disabled={disabled}
                disableClearable
                {...freightProps}
                className="autocomplete-input-credit"
                onChange={(e) =>
                  contactUpdate(e.target.textContent, "freight_reason")
                }
                value={
                  FreightClaimReasons.filter(
                    (prob) => prob.id === claim.freight_claim_reason
                  )[0]
                }
                isOptionEqualToValue={(option, value) =>
                  option.id === value ? value.id : value
                }
                renderInput={(params) => (
                  <TextField
                    className="autocomplete-problems"
                    {...params}
                    variant="standard"
                  />
                )}
              />
            </Col>
            <Col>
              <div className="info-header">Claim on shortage/damaged</div>
              <Autocomplete
                disabled={disabled}
                disableClearable
                {...reportedProps}
                className="autocomplete-input-credit"
                onChange={(e) =>
                  contactUpdate(e.target.textContent, "reported")
                }
                value={
                  ClaimeportedIssue.filter(
                    (prob) => prob.id === claim.reported_issue
                  )[0]
                }
                isOptionEqualToValue={(option, value) =>
                  option.id === value ? value.id : value
                }
                renderInput={(params) => (
                  <TextField
                    className="autocomplete-problems"
                    {...params}
                    variant="standard"
                  />
                )}
              />
            </Col>
            <Col>
              <FormGroup className="mt-5">
                <FormControlLabel
                  id="driverAware"
                  disabled={disabled}
                  label="Was driver aware?"
                  control={
                    <Checkbox
                      defaultChecked={false}
                      onClick={(e) =>
                        updateClaimItem(e.target.checked, "driver")
                      }
                    />
                  }
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      </Card>
    </>
  );
};

export default ClaimInfoModule;
